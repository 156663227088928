@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* @import "./components/react-select.css";
@import "./components/loader.css"; */

HTML {
  height: 100%;
}

BODY {
  height: 100%;
  /* background-image: url("../media/beams-corner-light.jpg");
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: 600px; */
}

@layer base {
  html {
    @apply bg-slate-50 text-gray-700;
  }
}

.disabled-events * {
  pointer-events: none;
}

.link {
  @apply text-blue-900 hover:text-blue-700;
}

.button.primary {
  @apply w-full py-2 sm:py-3 px-4 border border-transparent text-sm sm:text-base font-medium rounded-md text-white bg-primary-dark hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
  @apply disabled:bg-primary-light;
}

.button.cta {
  @apply inline-flex items-center h-14 py-2 sm:py-3 px-4 border border-transparent text-sm sm:text-base font-light rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500;
  @apply disabled:bg-gray-300;
}

/* ANIMACIÓN DE REACT ROUTER TRANSITION */

.slide-enter {
  /* background-color: white;
  position: absolute;
  width: 100%;
  left: 100%; */
  opacity: 0;
  z-index: 1;
}

.slide-enter.slide-enter-active {
  /* left: 0%; */
  opacity: 1;
  transition: opacity 250ms ease-in;
}

/* LOADER */

.spinner {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  padding-top: 2px;
  text-align: center;
}

.spinner > div {
  width: 14px;
  height: 14px;
  margin-right: 3px;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner > div {
  background-color: #333;
}

.spinner.white > div {
  background-color: white;
  opacity: 0.7;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* Se usa en los comentarios ya que Quill devuelve HTML sin clases */

.parsed-html h1 {
  @apply text-2xl font-bold;
}

.parsed-html h2 {
  @apply text-xl font-bold;
}

.parsed-html h3 {
  @apply text-lg font-bold;
}

.parsed-html ol {
  @apply list-decimal ml-8;
}

.parsed-html ul {
  @apply list-disc ml-8;
}

.ql-editor {
  min-height: 150px;
}
